import React from 'react';
import { LayoutBlank } from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import FillForm from '../../components/organisms/Form/FillForm';
import AuthWrapper from '../../components/layout/AuthWrapper';

const LearnerFormPage = () => {
  return (
    <AuthWrapper userRole="all">
      <Head title="Learner Form" />
      <LayoutBlank>
        <FillForm userType="learner" />
      </LayoutBlank>
    </AuthWrapper>
  );
};

export default LearnerFormPage;
